import React from "react";
import dynamic from "next/dynamic";
import { PersonalizeLayout } from "../view/PersonalizeLayout";

const Skeleton = dynamic(() => import("react-loading-skeleton"), {
    ssr: false,
});

export const GameplaySkeleton = () => {
    return (
        <PersonalizeLayout>
            <div className="flex flex-col w-i">
                <Skeleton width={"100%"} height={"56px"} className="mb-3 " />
                <div className="flex flex-row items-center justify-center my-4">
                    <Skeleton height={"33px"} width="250px" />
                </div>
                <div className="px-4 mb-8">
                    <Skeleton height={"80vh"} />
                </div>
            </div>
        </PersonalizeLayout>
    );
};
