import { renderer } from "@modules/flow/components/blocks/StoryBlock/renderer";
import get from "lodash/get";
import { getParts } from "../../../../utils/helpers";
import { RenderPart } from "./RenderPart";
import { useEffect, useState } from "react";
interface OptionCardProps {
    selected?: boolean;
    correct?: boolean;
    changeColor?: boolean;
    review?: boolean;
    onClick: any;
    src: string;
    textStyle?: any;
    hasTextonly?: boolean;
    hasPadding?: boolean;
    feedback?: any;
    showFeedback?: boolean;
}

export const OptionCard: React.FC<OptionCardProps> = ({
    selected = true,
    correct = true,
    review = true,
    onClick,
    src,
    textStyle = {},
    changeColor,
    hasTextonly = true,
    feedback = null,
    showFeedback = false,
    hasPadding = false,
}) => {
    const [line_1, setLine_1] = useState(null);
    const [line_2, setLine_2] = useState(null);

    useEffect(() => {
        setLine_1(getLine("line_1"));
        setLine_2(getLine("line_2"));
    }, [hasTextonly, showFeedback, feedback]);

    const getLine = (id: string) => {
        if (
            !hasTextonly ||
            !showFeedback ||
            !feedback ||
            !feedback.values.length
        )
            return null;
        const feed = feedback?.values?.filter(
            (f) => f.name === id && f.show === "always",
        );
        if (feed?.length) {
            return renderer(get(feed, [0, "value"]));
        }
        const isCorrect = selected && correct;
        if (isCorrect) {
            return renderer(
                get(
                    feedback?.values?.filter(
                        (f) =>
                            f.name === id &&
                            (f.show === "correct" || f.show == "selected"),
                    ),
                    [0, "value"],
                ),
            );
        }

        if (review && correct) {
            return renderer(
                get(
                    feedback?.values?.filter(
                        (f) =>
                            f.name === id &&
                            (f.show === "correct" || f.show == "not_selected"),
                    ),
                    [0, "value"],
                ),
            );
        }

        if (review && !correct) {
            return renderer(
                get(
                    feedback?.values?.filter(
                        (f) =>
                            f.name === id &&
                            (f.show === "incorrect" || f.show == "selected"),
                    ),
                    [0, "value"],
                ),
            );
        }
    };

    return (
        <>
            {hasTextonly && (
                <div
                    style={{
                        transition: `opacity ${2}s ease-in 1s`,
                        opacity: showFeedback ? "1" : "0",
                    }}
                >
                    <div
                        className={`my-2 ${!line_1 && !line_2 && showFeedback ? "h-[20px]" : ""
                            }`}
                    >
                        {line_1 && (
                            <div
                                className={"py-0.5 px-2"}
                                style={{ fontSize: "10px", fontWeight: "600" }}
                            >
                                {line_1}
                            </div>
                        )}
                        {line_2 && (
                            <div
                                className={"py-1 px-2 w-min"}
                                style={{
                                    fontSize: "10px",
                                    fontWeight: "500",
                                    backgroundColor: correct ? "#27AE60" : "",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {line_2}
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div
                onClick={onClick}
                className={`border ${hasTextonly
                    ? "pl-4 pr-8 py-4"
                    : "relative flex justify-center font-content"
                    } mt-3 rounded-md sort-item
                ${hasTextonly
                        ? (selected || review
                            ? " border-dark-purple"
                            : "border-bright-gray") +
                        " " +
                        (review
                            ? correct
                                ? "bg-nyanza bg-opacity-100"
                                : "bg-red bg-opacity-10"
                            : changeColor
                                ? "bg-corn-silk"
                                : "bg-pastel-yellow bg-opacity-10")
                        : review
                            ? correct
                                ? "border-[#27AE60] border-2"
                                : "border-[#EB5757] border-2"
                            : selected
                                ? "border-gargoyle-gas border-2"
                                : "border-bright-gray"
                    }`}
            >
                {review &&
                    !hasTextonly &&
                    showFeedback &&
                    feedback &&
                    feedback.values.length && (
                        <div
                            className={`absolute font-content top-0 left-0 p-1 text-white text-[14px] ${correct ? "bg-[#27AE60]" : "bg-[#EB5757]"
                                }`}
                        >
                            {renderer(feedback.values[0].value, {
                                textStyle: {
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: "21px",
                                    color: "#333333",
                                },
                            })}
                        </div>
                    )}
                {!hasPadding
                    ? renderer(src, {
                        textStyle: {
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "21px",
                            color: "#333333",
                        },
                    })
                    : getParts(src).map((part, pIndex) => (
                        <RenderPart
                            part={part}
                            key={pIndex}
                            textStyle={textStyle}
                            imgStyle={
                                hasPadding
                                    ? ""
                                    : "flex self-stretch object-contain max-w-full"
                            }
                        />
                    ))}
            </div>
        </>
    );
};
