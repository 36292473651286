import { isDVHSupported } from "@utils/helpers";
import { SafeArea } from "capacitor-plugin-safe-area";
import { Fragment, ReactElement, useRef, useState, useEffect } from "react";

interface SetLayoutProps {
    children: ReactElement[] | ReactElement;
    setXWeb?: any;
    viewStyle?: any;
    containerStyle?: any;
}

export const PersonalizeLayout: React.FC<SetLayoutProps> = ({
    children,
    setXWeb,
    viewStyle,
    containerStyle,
}): ReactElement => {
    const boxRef = useRef();
    const [paddingTop, setPaddingTop] = useState(0);

    // X

    // This function calculate X and Y
    const getPosition = () => {
        const x = boxRef && boxRef?.current && boxRef?.current?.offsetLeft;
        setXWeb && setXWeb(x);
    };

    // Get the position of the red box in the beginning
    useEffect(() => {
        boxRef && boxRef?.current && getPosition();
    }, []);

    // Re-calculate X and Y of the red box when the window is resized by the user
    useEffect(() => {
        window.addEventListener("resize", getPosition);
        return () => window.removeEventListener("resize", getPosition);
    }, []);

    // useEffect(() => {
    //     SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
    //         setPaddingTop(statusBarHeight);
    //     });
    // }, []);

    return (
        <div className="flex justify-center">
            <div
                className={`personalize-container-${isDVHSupported() ? "dvh" : "vh"}`}
                style={containerStyle}
            >
                <div
                    className={"personalize-view"}
                    style={viewStyle}
                    ref={boxRef}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
