// src/utils/convertUrlsToCdnAndImgKit.ts

// Define the types for the asset parameter
interface ConvertUrlsToCdnAndImgKitAsset {
    url: string;
    type?: string;
}

// Define the type for buckets with ImageKit and CDN support
const bucketsWithImagekitSupport: string[] = [
    "sets-gamify-assets",
    "homeworkapp-cdn",
]; // Add your bucket names

const bucketsWithCdnSupport: string[] = [
    "teacher-questions",
    "custom-homework-submissions",
    "hw-reports",
    "homeworkapp-cdn",
    "custom-homeworks",
    "school-onboarding",
    "sets-gamify-assets",
    "homework-submissions",
    "hw-sample-papers",
]; // Add your bucket names

const encodeForImageKit = (url: string): string => {
    return url.replace(/\+/g, "%20").replace(/%26/g, "&");
};

// List of static assets to exclude from transformation
const staticAssets: string[] = [
    // Add patterns or specific URLs for static assets
    "/static/",
    "/public/",
];

export const convertUrlsToCdnAndImgKit = (
    asset: ConvertUrlsToCdnAndImgKitAsset,
): string => {
    if (typeof asset !== "object" || !asset.url) {
        return asset.url; // Return the URL if it's not an object or if the URL is missing
    }

    const url = asset.url;

    if (!["IMAGE", "IMG"].includes(asset.type?.toUpperCase() ?? "")) {
        return url; // Return the URL if the type is not IMAGE or IMG
    }

    // Check if the URL is a static asset
    for (const staticAsset of staticAssets) {
        if (url.includes(staticAsset)) {
            return url; // Return the static asset URL as it is
        }
    }

    const imgKit = "https://ik.imagekit.io/thehomeworkapp";
    const s3 = ".s3.ap-south-1.amazonaws.com";
    const cdn = "https://cdn.homeworkapp.ai/";

    const isImgKit = url.includes("ik.imagekit.io/thehomeworkapp");
    const isS3 = url.includes(s3);
    const isCdn = url.includes(cdn);

    if (isImgKit) {
        return url; // Return the URL if it's already an ImageKit URL
    }

    if (isCdn) {
        const bucketName = ((url.split(cdn) ?? [])[1].split("/") ?? [])[0];

        if (bucketsWithImagekitSupport.includes(bucketName)) {
            return imgKit + (encodeForImageKit(url.split(bucketName)[1]) ?? ""); // Convert the CDN URL to ImageKit URL
        } else if (bucketName === "assets") {
            return (
                imgKit +
                (encodeForImageKit(
                    "/" + bucketName + url.split(bucketName)[1],
                ) ?? "")
            ); // Convert the CDN URL to ImageKit URL
        }

        return url; // Return the original URL if the bucket is not supported
    }

    if (isS3) {
        const bucketName = (url.split(s3) ?? [])[0]?.split("/")?.pop();
        const assetPath = (url.split(s3) ?? [])[1] ?? "";

        if (bucketsWithImagekitSupport.includes(bucketName ?? "")) {
            return imgKit + assetPath; // Convert the S3 URL to ImageKit URL
        }
        if (bucketsWithCdnSupport.includes(bucketName ?? "")) {
            return cdn + (bucketName ? bucketName + assetPath : assetPath); // Convert S3 URL to CDN URL
        }
    }

    return url; // Return the original URL if none of the conditions match
};
