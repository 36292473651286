import dynamic from "next/dynamic";
export { MathRender } from "./MathRender";
// export { RenderPart } from "./RenderPart";
export { OptionCard } from "./OptionCard";
// export { LearnMeme } from "./LearnMeme";
// export { LearnHeader } from "./LearnHeader";
// export { LearnStep } from "./LearnStep";

// Dynamic imports
// export const MathRender = dynamic(() =>
//     import("./MathRender").then((module) => module.MathRender),
// );

export const RenderPart = dynamic(() =>
    import("./RenderPart").then((module) => module.RenderPart),
);

// export const OptionCard = dynamic(() =>
//     import("./OptionCard").then((module) => module.OptionCard),
// );

export const LearnMeme = dynamic(() =>
    import("./LearnMeme").then((module) => module.LearnMeme),
);

export const LearnHeader = dynamic(() =>
    import("./LearnHeader").then((module) => module.LearnHeader),
);

export const LearnStep = dynamic(() =>
    import("./LearnStep").then((module) => module.LearnStep),
);
