import { ReactElement, useEffect, useRef } from "react";

interface BottomSheetProps {
    children: ReactElement[] | ReactElement;
    callback?: any;
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
    children,
    callback = null,
}) => {
    const node = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick, false);
        return () => {
            document.removeEventListener("mousedown", handleClick, false);
        };
    }, []);

    const handleClick = (e: any) => {
        if (!callback) return null;
        if (node && node.current && !node.current.contains(e.target)) {
            callback({ method: "click_overlay" });
        }
        return;
    };

    return (
        <div className="bottomSheetDialog">
            <div className="bottomSheetDocument" ref={node}>
                {children}
            </div>
        </div>
    );
};
