var isArray = Array.isArray;
var objectKeys = Object.keys;

function copy(val, parent = null, map = new Map()) {
  if (!val) return val;
  if (typeof val === 'object') {
    if (map.has(val)) {
      // Return the already copied object to maintain circular references and correct parent references
      return map.get(val);
    }
    
    var copiedObject;
    if (isArray(val)) {
      copiedObject = [];
      map.set(val, copiedObject);  // Track the original array and its copy
      for (var i = 0; i < val.length; i++) {
        copiedObject.push(copy(val[i], copiedObject, map));
      }
    } else {
      copiedObject = {};
      map.set(val, copiedObject);  // Track the original object and its copy
      for (var key of objectKeys(val)) {
        if (key === "parent") {
          // Set 'parent' to the passed parent object, which varies by depth
          copiedObject[key] = parent;
        } else {
          copiedObject[key] = copy(val[key], copiedObject, map);
        }
      }
    }
    return copiedObject;
  }
  return val;
}


export default copy;
