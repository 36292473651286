import React from "react";

import { getParts } from "@utils/helpers";
import { RenderPart } from "@modules/gameplay/components/base";
import Image from "next/image";

const PreviewQuestionCard = (props: any) => {
    const {
        idx = 1,
        tags = [],
        questionText,
        options = [],
        subQuestions = [],
    } = props;
    function renderText(text: string) {
        return getParts(text).map((part, pIndex) => (
            <RenderPart part={part} key={pIndex} />
        ));
    }

    return (
        <>
            <div className={"pad-16"}>
                <div className={"preview-flex"}>
                    <span className={"preview-question-info"}>
                        {`Q.${idx}`}
                    </span>
                    <span className={"width-6"} />

                    {tags.map((tag: string, t: number) => (
                        <span
                            key={`tag${t}`}
                            className="preview-flex preview-tag"
                        >
                            <Image
                                width={14}
                                height={14}
                                alt="-"
                                src="https://cdn.homeworkapp.ai/sets-gamify-assets/math-ai-assets/assets/images/speed.png"
                            />
                            <span className={"width-6"} />
                            {tag}
                        </span>
                    ))}
                </div>

                <div className={"height-20"} />
                <div className={"preview.text"}>{renderText(questionText)}</div>
                <div className={"height-16"} />

                {!options.length &&
                    subQuestions.map((subQuestion: any, i: number) => (
                        <PreviewQuestionCard
                            idx={i + 1}
                            tags={subQuestion.tags}
                            questionText={subQuestion.text}
                            options={subQuestion.options}
                        />
                    ))}
            </div>

            {!subQuestions.length &&
                options.map((option: any, idx: number) => (
                    <div className={"preview-option-container"}>
                        <span className={"preview-option-idx-content"}>
                            <span className={"preview-idx"}>
                                {String.fromCharCode(97 + idx).toUpperCase()}
                            </span>
                        </span>
                        <div className={"preview-option"}>
                            {renderText(option)}
                        </div>
                    </div>
                ))}
        </>
    );
};

export default PreviewQuestionCard;
