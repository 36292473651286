import dynamic from "next/dynamic";
const InfinitySpin = dynamic(() => import("react-loader-spinner"), {
    ssr: false,
});

export const Spinner = () => (
    <div className="grid h-screen place-items-center">
        <InfinitySpin color="grey" />
    </div>
);
