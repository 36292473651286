import { FigmaJsonStates } from "@modules/home/view/activity/Components/Blocks/AllInOne/FigmaStates";
import { captureException } from "@sentry/nextjs";
import { safeDynamicFunction } from "@utils/helpers";

class ComputeFunctionHelper {
    private functions: any = {};
    private worksheetId: any = null;
    public createMultipleFunctions(
        functionDetails: any[],
        worksheetId: string,
    ): string {
        if (!functionDetails?.length) return "Batch of functions is empty";
        if (this.functions[worksheetId]) {
            return "Functions already created";
        }

        this.functions[worksheetId] = [];
        functionDetails.forEach((func) => {
            const { name = "", output, params = [] } = func;
            const functionString = output.toString();
            const functionBody = safeDynamicFunction(
                functionString
                    .replaceAll("const value", "value")
                    .replaceAll("const defaults", "defaults"),
            );

            let updatedParams = [
                ...params,
                "defaults",
                "global_context_variables",
                "publishEvent",
                "pixiData",
                "all_variables",
            ];

            let logs = false;
            if (typeof window != "undefined")
                if (window.location?.search?.includes("logs=true")) logs = true;
            let wrappedFunctionBody = `
                ${!logs ? "var console = { log: function() {} };" : ""}
                ${functionBody}
            `;
            let newFunc;
            try {
                newFunc = new Function(...updatedParams, wrappedFunctionBody);
            } catch (e) {
                console.log(
                    "Error in creating function",
                    e,
                    name,
                    updatedParams,
                    wrappedFunctionBody,
                    functionDetails,
                );
                captureException(e);
                return null;
            }
            this.functions[worksheetId].push({
                name,
                componentIndex: func.componentIndex,
                params: updatedParams,
                function: new Function(...updatedParams, wrappedFunctionBody),
            });
        });
        return "Batch of functions created";
    }

    public executeFunction(
        name: string,
        args: any[],
        componentIndex: any,
        call = 0,
    ): any {
        if (call > 1) {
            throw new Error(`Function not found ${name}`);
        }
        const worksheetId = this.worksheetId;
        const funcNameFilter = this.functions[worksheetId]?.filter(
            (funcItem: any) => funcItem.name === name,
        );
        if (!funcNameFilter?.length) {
            const functionDetails =
                FigmaJsonStates.getJson(componentIndex)?.computeFunctions ?? [];
            this.createMultipleFunctions(
                functionDetails.map((i) => ({ ...i, componentIndex })),
                worksheetId,
            );
            return this.executeFunction(name, args, componentIndex, call + 1);
        }
        const funcObj = this.functions[worksheetId]?.find(
            (funcItem: any) =>
                funcItem.name === name &&
                (componentIndex === funcItem.componentIndex ||
                    funcItem.componentIndex === "all"),
        );
        const func = funcObj?.function;
        const paramsList = funcObj?.params;
        let argValues = [];
        // Update argValues using args and paramsList, update in order of paramsList
        // args is an object with keys as param names
        for (let i = 0; i < paramsList?.length; i++) {
            const param = paramsList[i];
            if (args[param] !== undefined) {
                argValues.push(args[param]);
            } else if (param == "all_variables") {
                argValues.push(FigmaJsonStates.getComponentVariables() ?? []);
            } else {
                argValues.push(undefined);
            }
        }
        if (!func) {
            // console.log("ERROR:INFO", { funcNameFilter, funcObj, func, paramsList, componentIndex })
            // throw new Error(`Function not found ${name}`);
            return null;
        }
        try {
            return func(...argValues);
        } catch (e) {
            console.log("Error in executing function", e, name, argValues);
            return null;
        }
    }

    public clearFunctions(): string {
        const worksheetId = this.worksheetId;
        if (this.functions[worksheetId]) {
            delete this.functions[worksheetId];
            return "All functions cleared";
        }
        this.worksheetId = null;
        return "No functions to clear";
    }

    public setWorksheetId(worksheetId: string) {
        this.worksheetId = worksheetId;
        return;
    }

    public getWorksheetId() {
        return this.worksheetId;
    }
}

const ComputeFunctionHelperObj = new ComputeFunctionHelper();

export default ComputeFunctionHelperObj;
