import React, { ReactElement, useEffect, useRef } from "react";

interface CustomModalProps {
    children: ReactElement[] | ReactElement;
    callback: any;
    shadow?: boolean;
    top?: string;
    modalStyle?: any;
    documentStyle?: any;
}

export const CustomModal: React.FC<CustomModalProps> = ({
    children,
    callback,
    shadow = false,
    top = "0px",
    modalStyle = {},
    documentStyle = {},
}) => {
    const node = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClick, false);
        return () => {
            document.removeEventListener("mousedown", handleClick, false);
        };
    }, []);

    const handleClick = (e: any) => {
        if (!callback) return null;
        if (node && node.current && !node.current.contains(e.target)) {
            callback({ method: "click_overlay" });
        }
        return;
    };

    return (
        <div
            role="dialog"
            className={
                "modalDialog" + " " + (shadow ? "modalDialogShadow" : "")
            }
            style={modalStyle}
        >
            <div
                className={
                    "modalDocument" +
                    " " +
                    (!shadow ? "modalDocumentWhite" : "")
                }
                style={{ top, ...documentStyle }}
            >
                <div className="modalContent" ref={node}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default CustomModal;
