import { useRef } from "react";
import { MathRender } from "./MathRender";
interface blankInterface {
    attempted?: boolean;
    correct?: boolean;
    options?: string[];
    correct_option?: string;
    selected_option?: string;
    answer?: string;
    correct_answer?: string[];
    mode?: string;
    custom_keyboard?: string[];
    correct_options?: string[];
}

interface blanksInterface {
    [key: string]: blankInterface;
}

const getBlankInfo = (blankIndex: any, blanks: blanksInterface = {}) => {
    const retBlank = {
        text: "",
        attempted: false,
        correct: false,
    };
    if (!blanks || blanks === {}) {
        return retBlank;
    }
    const key = String(blankIndex);
    if (!(key in blanks)) {
        return retBlank;
    }
    const blank = blanks[key];
    if (
        (!blank.custom_keyboard || !blank.custom_keyboard.length) &&
        (!blank.options || !blank.options.length)
    ) {
        return retBlank;
    }
    retBlank.attempted = !!blank.attempted;

    if (!blank.custom_keyboard || !blank.custom_keyboard.length) {
        const correctOption = Number(blank.correct_option);
        if (correctOption) {
            retBlank.text =
                blank.selected_option !== ""
                    ? blank.options[blank.selected_option]
                    : "";
            if (correctOption === blank.selected_option + 1) {
                retBlank.correct = true;
            }
        } else {
            const correctOptions = blank.correct_options;
            retBlank.text =
                blank.selected_option !== ""
                    ? blank.options[blank.selected_option]
                    : "";
            if (correctOptions.includes(blank.selected_option)) {
                retBlank.correct = true;
            }
        }
    } else {
        retBlank.text = blank.answer || "";
        if (blank?.correct_answer?.includes(blank.answer || "")) {
            retBlank.correct = true;
        }
    }

    return retBlank;
};

interface buildBlankProps {
    blankIndex: any;
    blanks?: blanksInterface;
    step?: number;
    blankClick?: any;
    currentStepBlank?: number[];
}

const BuildBlank: React.FC<buildBlankProps> = ({
    blankIndex,
    blanks = {},
    step = 0,
    blankClick = null,
    currentStepBlank,
}) => {
    const ref: any = useRef(null);
    const blank = getBlankInfo(blankIndex, blanks);

    const handleCScroll = () => {
        const rect = ref?.current?.getBoundingClientRect();
        const windowHeight =
            window?.innerHeight || document?.documentElement?.clientHeight;

        if (rect.bottom > windowHeight / 2) {
            ref?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
    };

    return (
        <div
            ref={ref}
            className={
                "my-2 mx-1 text-center rounded-md" +
                " " +
                (blank.text
                    ? "text-dark-charcoal"
                    : blank.attempted
                    ? "text-pale-pink"
                    : " text-silver-sand") +
                " " +
                (blank.attempted
                    ? blank.correct
                        ? "bg-nyanza"
                        : "bg-pale-pink"
                    : "border border-dark-purple bg-white ") +
                (blank.attempted ? "" : "cursor-pointer")
            }
            style={{
                width: "fit-content",
                minHeight: "32px",
                minWidth: "71px",
                fontSize: "14px",
                lineHeight: "14px",
            }}
            onClick={(event) => {
                event.stopPropagation();
                handleCScroll();
                blankClick && blankClick(step, blank, blankIndex);
            }}
        >
            <div
                className={
                    currentStepBlank &&
                    currentStepBlank[0] === step &&
                    currentStepBlank[1] === blankIndex
                        ? "border-2 border-gargoyle-gas rounded-md"
                        : ""
                }
                style={{
                    padding: "9px 12px",
                }}
            >
                {blank.text !== "" ? (
                    <MathRender src={blank.text || "Fill this"} />
                ) : (
                    <span>Fill this</span>
                )}
            </div>
        </div>
    );
};

interface RenderPartProps {
    part: any;
    blanks?: blanksInterface;
    step?: number;
    blankClick?: any;
    textStyle?: any;
    centerImage?: boolean;
    customImg?: boolean;
    loadImageCallback?: any;
    currentStepBlank?: number[];
    blankIndex?: any;
    imgStyle?: string;
}

export const RenderPart: React.FC<RenderPartProps> = ({
    part,
    blanks = {},
    step = 0,
    blankClick = null,
    textStyle = {},
    centerImage = false,
    customImg = false,
    loadImageCallback,
    currentStepBlank,
    blankIndex = null,
    imgStyle = "",
}) => {
    const imgClassName =
        imgStyle != ""
            ? imgStyle
            : `flex self-stretch object-contain max-w-full mt-3 mb-3 ${
                  centerImage ? "mx-auto" : ""
              } `;

    const handleImageLoad = () => {
        if (loadImageCallback) loadImageCallback();
        return;
    };

    return part ? (
        part.type === "parts" ? (
            <div
                style={{
                    ...(part.val.filter((p) => p.type == "fib").length
                        ? {
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                          }
                        : {}),
                }}
            >
                {part.val.map((p: any, i: any) => (
                    <RenderPart
                        key={`p_p_${i}`}
                        part={p}
                        blankClick={blankClick}
                        step={step}
                        blanks={blanks}
                        textStyle={textStyle}
                        centerImage={centerImage}
                        customImg={customImg}
                        loadImageCallback={loadImageCallback}
                        currentStepBlank={currentStepBlank}
                        blankIndex={p.blankIndex}
                        imgStyle={imgStyle}
                    />
                ))}
            </div>
        ) : part.type === "img" ? (
            customImg ? (
                <div style={{ minWidth: "300px" }}>
                    <img
                        alt="question"
                        src={part.val}
                        onLoad={handleImageLoad}
                    />
                </div>
            ) : (
                <img
                    alt="question"
                    className={imgClassName}
                    src={part.val}
                    onLoad={handleImageLoad}
                />
            )
        ) : part.type === "latex_div" ? (
            <div
                className="text-sm font-normal leading-5 text-dark-charcoal"
                style={textStyle}
            >
                <MathRender src={part.val} />
            </div>
        ) : part.type === "latex_span" ? (
            <span style={textStyle}>
                <MathRender src={part.val.replace(/\\n/g, "")} />
            </span>
        ) : part.type === "new_line" ? (
            <div className="py-2" />
        ) : part.type === "fib" ? (
            <BuildBlank
                blankIndex={blankIndex != null ? blankIndex : part.val}
                blanks={blanks}
                step={step}
                blankClick={blankClick}
                currentStepBlank={currentStepBlank}
            />
        ) : part.type === "dfrac" ? (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}
            >
                {part.subParts.map((p, i) =>
                    p.type === "latex_div" ? (
                        <>
                            <div
                                className="text-sm font-normal leading-5 text-dark-charcoal"
                                style={textStyle}
                            >
                                <MathRender src={p.val} />
                            </div>
                        </>
                    ) : p.type === "dfrac" ? (
                        <div
                            style={{
                                flexDirection: "column",
                                display: "flex",
                                width: "fit-content",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <RenderPart
                                    part={p.blankBlock[0].blankParts[0]}
                                    blankClick={blankClick}
                                    step={step}
                                    blanks={blanks}
                                    textStyle={textStyle}
                                    centerImage={centerImage}
                                    customImg={customImg}
                                    loadImageCallback={loadImageCallback}
                                    currentStepBlank={currentStepBlank}
                                />
                            </div>
                            <div
                                style={{
                                    borderBottom: "1px solid gray",
                                    display: "flex",
                                    margin: "0px 10px",
                                }}
                            ></div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <RenderPart
                                    part={p.blankBlock[1].blankParts[0]}
                                    blankClick={blankClick}
                                    step={step}
                                    blanks={blanks}
                                    textStyle={textStyle}
                                    centerImage={centerImage}
                                    customImg={customImg}
                                    loadImageCallback={loadImageCallback}
                                    currentStepBlank={currentStepBlank}
                                />
                            </div>
                        </div>
                    ) : (
                        <></>
                    ),
                )}
            </div>
        ) : part.type === "p" ? (
            <span>{part.children[0].text || ""}</span>
        ) : (
            <span>{part.val}</span>
        )
    ) : null;
};
