import React from "react";
import dynamic from "next/dynamic";
// import Latex from "katex-react";

const Latex = dynamic(() => import("katex-react"), {
    ssr: false,
});

interface MathRenderProps {
    src: string;
}

export const MathRender: React.FC<MathRenderProps> = ({ src }) => {
    if (src) {
        return (
            <Latex throwOnError={false} errorColor={"currentColor"}>
                {src}
            </Latex>
        );
    } else {
        return <span>{""}</span>;
    }
};
